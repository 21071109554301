@import "../../../util/styles.scss";

.link-warp {
  .link-number {
    color: $purpleColor;
    margin-right: 5px;
    @media only screen and (max-width: $screenMobile) {
      margin: 0;
    }
  }
  .link-to {
    color: $whiteColor;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: $purpleColor;
    }
  }
}
