@import "./util/styles.scss";

.App {
  text-align: center;
  padding-bottom: 20px;
}

.wrapper {
  width: 100%;
  margin: 0px auto 0;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  max-width: 80%;
}

.blur {
  filter: blur(5px) brightness(0.7);
}

@media only screen and (max-width: $screenMobile) {
  .wrapper {
    padding: 0 10px;
    max-width: 100%;
    margin: 0;
  }
}
