@import "../../util/styles.scss";

.Socials {
  @media only screen and (max-width: $screenMobile) {
    display: none;
  }
  .mail-to {
    position: fixed;
    right: 60px;
    bottom: 0;
    a {
      color: $greyColor;
      text-decoration: none;
      writing-mode: vertical-rl;
      margin-bottom: 20px;
      letter-spacing: 0.1rem;
      position: relative;
      transition: all 0.3s;
      top: 0px;
      &:hover {
        top: -3px;
        cursor: pointer;
        color: $purpleColor;
      }
    }
    .dVLQAC {
      width: 4px;
    }
  }
  .logo-row {
    position: fixed;
    left: 60px;
    bottom: 0;

    display: flex;
    gap: 35px;
    flex-direction: column;

    @media only screen and (max-width: $screenMobile) {
      flex-direction: row;
    }
    .logo-col {
      width: 25px;
      position: relative;
      transition: all 0.3s;
      top: 0px;

      @media only screen and (max-width: $screenMobile) {
        margin: 0 0 10px;
      }
      &:hover {
        top: -3px;
      }
      .svg-icon {
        fill: $greyColor;
        width: 25px;
        &:hover {
          cursor: pointer;
          fill: $purpleColor;
        }
      }
    }
  }
  .dVLQAC {
    border-left: 1px solid $greyColor;
    display: block;
    width: 1px;
    height: 130px;
    margin: 0px auto;
  }
}
@media only screen and (max-width: $screenMobile) {
  .mail-to {
    display: none;
  }
  .Socials {
    position: relative;
  }
}
