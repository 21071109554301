@import "../../util/styles.scss";

.Footer {
  display: block;
  .Socials {
    display: none;
  }
  .footer-txt {
    color: $greyColor;
    font-size: $font18;
    p {
      font-family: "source_code";
      color: $greyColor;
      padding-bottom: 20px;
      @media only screen and (max-width: $screenMobile) {
        font-size: $font14;
      }
    }
  }
}
@media only screen and (max-width: $screenMobile) {
  .Footer {
    .Socials {
      display: block;
      .logo-row {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 10%;
        position: relative;
        left: unset;
      }
      .dVLQAC {
        display: none;
      }
    }
  }
}
