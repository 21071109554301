@import "../../util/styles.scss";

.Work {
  padding-top: $paddingTopContainer;
  color: $whiteColor;
  box-sizing: border-box;
  width: 100%;
  @media only screen and (max-width: $screenMobile) {
    padding: 30px 10px 80px;
  }
  .my-carousel {
    width: 100%;
    position: relative;
    .navi-btn-wrap {
      bottom: 30px;
      top: unset;
      height: fit-content;
    }
    .navi-btn {
      padding: 0;
      margin: 0;
      opacity: 0.7;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
      svg {
        font-size: 40px;
        color: $purpleColor;
      }
    }
  }
  .title {
    .line {
      width: 80%;
    }
    .flex {
      width: 100%;
      h1 {
        min-width: 214px;
      }
    }
  }
  .card-container {
    padding-bottom: 100px;
    position: relative;
    @media only screen and (max-width: $screenMobile) {
      padding-bottom: 20px;
    }
  }
  .on-flex {
    gap: 30px;
  }
  .link-number {
    @include linkNumber;
  }
  .bottom-line {
    display: none;
    width: 100%;
    height: 1px;
    border-bottom: 2px grey solid;
    margin: 20px 0;
    @media only screen and (max-width: $screenMobile) {
      display: block;
    }
  }
  .line {
    @media only screen and (max-width: $screenMobile) {
      max-width: 29%;
    }
  }

  .article-title {
    font-family: "source_code";
    margin-bottom: 20px;
    font-size: 23px;
  }
}
@media (max-width: 2400px) and (min-width: 1281px) {
  .Work {
    width: 75%;
    margin: auto;
    max-width: 1050px;
    min-width: 950px;
  }
}
