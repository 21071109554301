$purpleColor: #d200ff;
$whiteColor: white;
$transparentHover: #6262629c;
$greyColor: #9d9d9d;
$purpleOpacity: #d200ff73;
$greyBlack: #131313;
$colorBlack: black;

@function color($color) {
  $remSize: $color;
  @return $remSize;
}
//fonts
$font50: 50px;
$font35: 35px;
$font20: 20px;
$font23: 23px;
$font26: 26px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font15: 15px;
$font14: 14px;
$font13: 13px;

$titleFont: clamp(40px, 5vw, 80px);

$screenMobile: 768px;

@font-face {
  font-family: source_code;
  src: url("../assets/Source_Code_Pro/SourceCodePro-VariableFont_wght.ttf");
}
@font-face {
  font-family: quicksilver;
  src: url("../assets/Quicksand/Quicksand-VariableFont_wght.ttf");
}
@font-face {
  font-family: quicksilver_bold;
  src: url("../assets/Quicksand/static/Quicksand-Bold.ttf");
}

$paddingTopContainer: 80px;
// $mobilePaddingTopContainer: 20px 10px 0px;
$mobilePaddingTopContainer: 10px 10px 80px;
$lineHeight24: 24px;
$lineHeight42: 42px;
$lineHeight28: 28px;

@mixin linkNumber {
  color: $purpleColor;
  margin-right: 5px;
  font-size: $font26;
  @media only screen and (max-width: $screenMobile) {
    font-size: $font18;
  }
}

@mixin wh300 {
  width: 300px;
  height: 300px;
}
@mixin wh200 {
  width: 200px;
  height: 200px;
}

@mixin section-text {
  color: $greyColor;
  line-height: $lineHeight24;
  letter-spacing: 0.5px;
  font-size: $font18;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  z-index: 1;
}

@mixin hover_line {
  .label {
    color: $purpleColor;
    position: relative;
    cursor: pointer;
    a {
      color: $purpleColor;
      text-decoration: none;
    }
  }
  .label:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    width: 0;
    bottom: -5px;
    background: $purpleColor;
    height: 1px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .label:hover:after,
  .label:focus:after,
  .label:active:after {
    left: 7px;
    right: auto;
    width: 95%;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@mixin flex-col {
  display: flex;
  flex-direction: column;
}
