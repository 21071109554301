@import "./util/styles.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: quicksilver;
  background: url("./assets/pics/black_back.jpeg") repeat;
}

.dark-mode {
  background: $whiteColor;
  color: $colorBlack;
  .Contact p,
  .About .about-text {
    color: #3a3a3a;
  }
  .About {
    .tech-col,
    .about-text {
      color: #3a3a3a;
    }
  }
  .Experience {
    .title {
      color: $colorBlack;
    }
    .tab-btn {
      color: #3a3a3a;
    }
    .experience-details {
      .description-warp .description-row,
      .period {
        color: #3a3a3a;
      }
    }
  }

  .ProjectCard {
    .image-container .filter {
      background-color: rgb(255 255 255 / 45%);
    }
    .section-txt {
      .tech-row,
      h3 {
        color: $colorBlack;
        @media only screen and (max-width: $screenMobile) {
          color: $whiteColor;
        }
      }
    }
  }
  .experience-title {
    color: $colorBlack;
  }
  .article-title,
  .title {
    color: $colorBlack !important;
  }

  .resume-btn a {
    color: $colorBlack !important;
  }
  .Work .articles-warp {
    background: whitesmoke;
  }
  .Header {
    background: whitesmoke;
    border-bottom: 1px solid rgb(212, 212, 212);

    .navbar {
      .link-to {
        color: $colorBlack;
      }
    }
  }
  .Home .full-name,
  .description {
    color: $colorBlack !important;
  }
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p {
  padding: 0;
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.on-flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
