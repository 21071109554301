@import "../../util/styles.scss";
#site-header.hide {
  top: -60px;
}
.hide {
  top: -60px;
}
.MobileHeader {
  z-index: 2;
  height: 60px;
  background-color: #131313;
  box-sizing: border-box;
  padding: 0px 10px;
  transition: all 0.5s ease;
  display: none;
  position: sticky;
  top: 0;
}

.drawer-root {
  .drawer-row {
    background-color: $colorBlack;
    width: 300px;
    justify-content: start;
    gap: 2%;
    z-index: 1;
    padding-top: 100px;
    .link-warp {
      flex-direction: column;
      margin: 10px 0;
    }
    .resume-btn {
      width: 60px;
      margin: 0 auto;
    }
  }
}
.burger-warp {
  display: none;
  z-index: 1400;
  position: fixed;
  top: 0;
  right: 0;
  top: 10px;
}
@media only screen and (max-width: $screenMobile) {
  .MobileHeader {
    display: block;
  }
  .burger-warp {
    display: block;
  }
}
