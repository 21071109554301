@import "../../../util/styles.scss";

.resume-btn {
  color: #64ffda;
  background-color: transparent;
  border: 1px solid $purpleColor;
  padding: 6px 13px;
  border-radius: 3px;
  a {
    text-decoration: none;
    color: $whiteColor;
  }
  &:hover {
    background-color: $transparentHover;
    cursor: pointer;
  }
}
