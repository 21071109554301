@import "../../util/styles.scss";

$borderRadius: 5px;
.About {
  padding-top: 120px;
  color: $whiteColor;
  text-align: left;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .link-number {
    @include linkNumber;
  }

  .title {
    max-width: 650px;

    .line {
      max-width: 70%;
      min-width: unset;
    }
  }

  .about-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
    max-width: 850px;
    @media only screen and (max-width: $screenMobile) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      background: #1a1a1a;
      padding: 10px 0 30px;
      border-radius: 5px;
    }

    .about-text-warp {
      @include section-text;
      max-width: 650px;

      .about-text {
        margin-bottom: 30px;
        white-space: pre-line;
        @media only screen and (max-width: $screenMobile) {
          padding: 0 10px;
        }
      }

      .tech-warp {
        flex-flow: wrap;
        justify-content: space-between;
        max-width: 500px;
        font-size: $font16;

        .tech-col {
          margin: 10px 0;
          min-width: 130px;
          font-family: source_code;
          font-size: $font14;
        }

        svg {
          color: $purpleColor;
          margin-right: 5px;
        }
      }
    }

    .img-warp {
      position: relative;
      width: fit-content;

      .my-pic {
        @include wh300;
        z-index: 4;
        border-radius: $borderRadius;
        transition: all 0.5s;
        filter: brightness(0.7);

        &:hover {
          filter: brightness(1);

          + .square {
            left: $font15;
            top: $font15;

            @media only screen and (max-width: $screenMobile) {
              left: 5px;
              top: 5px;
            }
          }
        }

        @media only screen and (max-width: $screenMobile) {
          position: relative;
        }
      }

      .square {
        border-radius: $borderRadius;
        @include wh300;
        border: 1px solid $purpleColor;
        position: absolute;
        left: 30px;
        top: 30px;
        z-index: -1;
        transition: all 0.5s;

        @media only screen and (max-width: $screenMobile) {
          z-index: 0;
        }
      }

      @media only screen and (max-width: $screenMobile) {
        .my-pic,
        .filter,
        .square {
          @include wh200;
        }
        .square {
          left: $font15;
          top: $font15;
        }
      }
    }
  }
}

@media only screen and (max-width: $screenMobile) {
  .About {
    padding: 10px 10px 80px;
    height: unset;

    .title {
      .line {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 2400px) and (min-width: 1281px) {
  .About {
    width: 80%;
    margin: auto;

    .about-row {
      max-width: 1024px;
    }
  }
}
