@import "../../../util/styles.scss";

.right {
  justify-content: left;

  .image-container {
    left: unset !important;
    right: 0;
  }

  .section-txt {
    text-align: left !important;

    .tech-row {
      justify-content: left !important;
    }
  }
}

.left {
  justify-content: right;

  .section-txt {
    align-items: flex-end;
    @media only screen and (max-width: $screenMobile) {
      align-items: unset;
    }
  }
}

.ProjectCard {
  min-height: 350px;
  box-sizing: border-box;

  @media only screen and (max-width: $screenMobile) {
    justify-content: center;
    min-height: unset;
    flex-direction: column;
    align-items: flex-start;
    background: #1a1a1a;
    border-radius: 5px;
    padding: 10px;
  }

  h5 {
    color: $purpleColor;
  }

  .image-display-none {
    display: none;
  }

  .image-container {
    position: absolute;
    top: 20px;
    left: 0;

    @media only screen and (max-width: $screenMobile) {
      width: 100%;
      position: relative;
      top: unset;
      left: unset;
    }

    .image-warp {
      img {
        //filter: brightness(0.5);
        width: 100%;
        height: 300px;
        transition: all 0.5s ease;

        //&:hover {
        //  filter: brightness(1);
        //}

        @media only screen and (max-width: $screenMobile) {
          height: unset;
        }
      }
    }
  }

  .section-txt {
    text-align: right;
    @include section-text;

    .label {
      line-height: 0;
    }

    .tech-row {
      display: flex;
      justify-content: right;
      gap: 10px;
      font-family: "source_code";
      font-size: $font14;
      color: $whiteColor;
      @media only screen and (max-width: $screenMobile) {
        justify-content: left;
        font-size: 10px;
        color: $greyColor;
      }
    }

    svg {
      color: $greyColor;
      transition: all 0.8 ease;

      &:hover {
        color: $purpleColor;
        animation: spin 0.5s linear backwards;
      }
    }

    h3 {
      color: white;
    }

    .txt {
      font-family: sans-serif;
      font-weight: 400;
      padding: 20px 20px 20px 10px;
      background: #001b29;
      width: 530px;
      position: relative;
      box-shadow: 0 10px 15px -5px black;
      border-radius: 5px;
      box-sizing: border-box;
      @media only screen and (max-width: $screenMobile) {
        padding: 0;
        width: 100%;
        box-shadow: unset;
        background: transparent;
      }
    }

    font-weight: 900;

    a {
      text-decoration: none;
      color: $purpleColor;
      font-family: source_code;
    }

    @media only screen and (max-width: $screenMobile) {
      text-align: left !important;
    }
  }
}

@media only screen and (max-width: $screenMobile) {
  .ProjectCard .image-container .image-warp img {
    filter: unset;
  }
  .image-display {
    display: none;
  }
  .ProjectCard .image-display-none {
    display: block;
  }
}
