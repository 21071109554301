@import "../../util/styles.scss";

#site-header.hide {
  top: -60px;
}
.hide {
  top: -60px;
}
.Header {
  transition: all 0.5s ease;
  font-family: source_code;
  padding: 0px 50px 0 43px;
  background-color: #131313;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 60px;
  .navbar {
    justify-content: space-between;
    .left-nav {
      @media only screen and (max-width: $screenMobile) {
        display: none;
      }
    }
    .right-nav {
      gap: 20px;
      @keyframes fadeIn {
        0% {
          transform: translateY(100%);
          opacity: 0;
        }
        55% {
          transform: translateY(0%);
          opacity: 0.2;
        }
        100% {
          transform: translateY(0%);
          opacity: 1;
        }
      }
      .nav-link-warp-0 {
        opacity: 0;
        animation: fadeIn 0.5s cubic-bezier(0.25, 0.66, 0.87, 0.36) forwards;
      }
      .nav-link-warp-1 {
        opacity: 0;
        animation: fadeIn 0.6s cubic-bezier(0.25, 0.66, 0.87, 0.36) forwards;
      }
      .nav-link-warp-2 {
        opacity: 0;
        animation: fadeIn 0.7s cubic-bezier(0.25, 0.66, 0.87, 0.36) forwards;
      }
      .nav-link-warp-3 {
        opacity: 0;
        animation: fadeIn 1s cubic-bezier(0.25, 0.66, 0.87, 0.36) forwards;
      }
      svg {
        cursor: pointer;
      }
    }

    .logo-img {
      width: 50px;
    }
    .resume-btn {
      color: #64ffda;
      background-color: transparent;
      border: 1px solid $purpleColor;
      padding: 6px 13px;
      border-radius: 3px;
      @media only screen and (max-width: $screenMobile) {
        margin: 0;
      }
      a {
        text-decoration: none;
        color: $whiteColor;
      }
      &:hover {
        background-color: $transparentHover;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: $screenMobile) {
  .Header {
    display: none;
  }
}
