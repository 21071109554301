@import "../../../util/styles.scss";

.title {
  box-sizing: border-box;
  font-family: "source_code";
  text-align: left;
  margin-bottom: 20px;
  max-width: 100%;
  gap: 10px;

  .line {
    border-bottom: 1px solid grey;
    width: 100%;
  }
}

@media only screen and (max-width: $screenMobile) {
  .title {
    h1 {
      font-size: 23px;
    }
    .line {
      display: none;
    }
  }
}
