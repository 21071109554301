@import "../../util/styles.scss";

.Contact {
  padding-top: $paddingTopContainer;
  height: 90vh;
  color: $whiteColor;
  text-align: center;
  box-sizing: border-box;
  @include flex-col;
  justify-content: space-between;
  .top-row {
    @include flex-col;
    //padding-top: 80px;
    gap: 20px;
    .get-in-t {
      font-size: 50px;
      @media only screen and (max-width: $screenMobile) {
        font-size: 26px;
      }
    }
  }
  .title {
    justify-content: center;
    margin: 0;
    .flex {
      align-items: baseline;
    }
    h1 {
      color: $purpleColor;
      font-weight: unset;
      font-size: 26px;
      @media only screen and (max-width: $screenMobile) {
        font-size: 18px;
      }
    }
    .line {
      display: none;
    }
  }
  @media only screen and (max-width: $screenMobile) {
    padding: 10px 10px 20px;
    height: unset;
    gap: 20px;
  }
  h1 {
    text-align: center;
  }
  p {
    white-space: break-spaces;
    @include section-text;
  }
  .link-number {
    @include linkNumber;
  }
  .resume-btn {
    width: fit-content;
    margin: 20px auto;
  }
}
